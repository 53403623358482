<template>
  <div>
    <slot-rule-aside
      v-if="aside === 'slot_rule'"
      :active="true"
      :sku="sku"
      :resource="slotRuleData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <slot-rule-generator-aside
      v-if="aside === 'generator'"
      :active="true"
      :sku="sku"
      :resource="slotRuleData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    >
    </slot-rule-generator-aside>

    <v-card class="mb-2">
      <v-card-title>
        <span>{{ sku.name.en }}'s Slot Rules</span>
        <v-spacer></v-spacer>
        <v-btn
          v-if="(product.nature === 'service') || slotRules.length === 0"
          color="primary"
          class="mb-4 me-3"
          @click="toggleRuleAside(null, 'slot_rule')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Rule</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="slotRules"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- sort -->
        <template #[`item.sort`]="{item}">
          <div class="d-flex align-center">
            {{ item.sort }}
          </div>
        </template>

        <!-- stock -->
        <template #[`item.stock`]="{item}">
          <div class="d-flex align-center">
            {{ item.stock }}
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <div class="d-flex align-center">
            <v-chip :color="item.active ? 'success' : ''">
              {{ item.active ? 'active' : 'inactive' }}
            </v-chip>
          </div>
        </template>

        <!-- Days in week -->
        <template #[`item.days`]="{item}">
          <div class="d-flex align-center">
            {{ formatDaysInWeek(item.days_in_week) }}
          </div>
        </template>

        <!-- duration minutes -->
        <template #[`item.dm`]="{item}">
          <div class="d-flex align-center">
            {{ item.duration_minutes }} (+{{ item.gap_after_minutes }}) mins
          </div>
        </template>

        <!-- From to -->
        <template #[`item.fromto`]="{item}">
          <div class="d-flex align-center">
            {{ item.start_from_hour | hourToString }} - {{ item.end_to_hour | hourToString }}
          </div>
        </template>

        <!-- From to -->
        <template #[`item.price_group`]="{item}">
          <div class="d-flex align-center">
            {{ t(item.price_group.name) }}
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleRuleAside(item, 'slot_rule')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update Rule</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="toggleRuleGenerator(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCog }}
                  </v-icon>
                  <span>Generate Manually</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-card>
      <v-card-title>
        Time Slots
      </v-card-title>
      <v-card-text>
        <slot-previewer
          v-if="ready"
          :rule="null"
          :sku="sku"
          :price-groups="priceGroups"
        >
        </slot-previewer>
      </v-card-text>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { mdiCog, mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

import { t } from '@/plugins/i18n'
import { hourToString } from '@core/utils/filter'
import usePriceForm from '../../price-resource/usePriceForm'
import SlotPreviewer from '../../slot-resource/SlotPreviewer.vue'
import SlotRuleAside from '../../slot-resource/SlotRuleAside.vue'
import SlotRuleGeneratorAside from '../../slot-resource/SlotRuleGeneratorAside.vue'
import useSlotRuleForm from '../../slot-resource/useSlotRuleForm'

export default {
  components: { SlotRuleAside, SlotRuleGeneratorAside, SlotPreviewer },

  filters: { hourToString },

  props: {
    product: { type: Object, required: true },
    sku: { type: Object, required: true },
  },

  setup(props) {
    const { slotRules, loadSlotRules } = useSlotRuleForm()
    const { priceGroups, loadPriceGroups } = usePriceForm()

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'DURATION (+GAP)', value: 'dm', sortable: false },
      { text: 'SORT', value: 'sort', sortable: false },
      { text: 'STOCK', value: 'stock', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },
      { text: 'Mon-Sun', value: 'days', sortable: false },
      { text: 'From-To', value: 'fromto', sortable: false },
      { text: 'PRICE GROUP', value: 'price_group', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const slotRuleData = ref(null)
    const aside = ref('') // rule, slot, generator

    const closeAside = bol => {
      if (!bol) {
        slotRuleData.value = null
        aside.value = null
      }
    }

    const toggleRuleAside = slot => {
      slotRuleData.value = slot
      aside.value = 'slot_rule'
    }

    const toggleRuleGenerator = slot => {
      slotRuleData.value = slot
      aside.value = 'generator'
    }

    const ready = ref(false)

    const refresh = async () => {
      console.log('refresh!')
      ready.value = false
      await loadSlotRules(props.sku)
      await loadPriceGroups(props.sku)
      closeAside()
      ready.value = true
    }

    const formatDaysInWeek = text => {
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      const parts = text.split(',')

      return parts
        .map((p, i) => (p === '1' ? days[i] : null))
        .filter(p => p)
        .join(',')
    }

    watch(props.sku, sku => {
      console.log('sku changed', sku)
      refresh()
    })

    onMounted(() => {
      refresh()
    })

    return {
      priceGroups,
      slotRules,
      tableColumnHeaders,
      slotRuleData,
      aside,
      ready,
      refresh,
      closeAside,
      toggleRuleAside,
      toggleRuleGenerator,
      formatDaysInWeek,
      t,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiCog,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
