var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('div',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en)+"'s Voucher Groups")]),_c('v-chip',{staticClass:"ml-2"},[_vm._v(" STOCK: "+_vm._s(_vm.sku.stock)+" ")])],1),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.voucherGroups,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.stock)+" ("+_vm._s(item.stock_original)+") ")])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.applicable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.applicable_at)))]):_c('span',[_vm._v("Any time.")])])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.last_purchasable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.last_purchasable_at)))]):_c('span',[_vm._v("Never.")])])]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.expires_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.expires_at)))]):_c('span',[_vm._v("Never expires")])])]}}],null,true)})],1),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.t(_vm.sku.name))+"'s all Vouchers ")]),_c('v-card-text',[(_vm.ready)?_c('voucher-previewer',{attrs:{"rule":null,"sku":_vm.sku},on:{"updated":_vm.refresh}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }