var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.aside === 'slot')?_c('slot-aside',{attrs:{"active":true,"sku":_vm.sku,"resource":_vm.slot},on:{"changed":function (slot) { _vm.closeAside(false); _vm.refresh() },"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":4}},[_c('v-date-picker',{attrs:{"color":"primary","range":"","events":_vm.functionEvents},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-col',{attrs:{"cols":8}},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.matchSlots,"options":_vm.options,"server-items-length":_vm.matchSlots.length,"loading":_vm.loading,"height":400,"items-per-page":999,"show-select":"","hide-default-footer":"","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stock)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.priceInfo(item))+" (~"+_vm._s(_vm._f("money")(item.reference_price))+") ")]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateSimple")(item.started_at))+" - "+_vm._s(_vm._f("dateSimple")(item.ended_at))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Active' : 'Inactive')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setSlot(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Update")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('div',[(_vm.selectedRows.length)?[_c('p',{staticClass:"mr-2"},[_vm._v(" Bulk Actions to "+_vm._s(_vm.selectedRows.length)+" items: ")]),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"success","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.updateAllSlots({ active: 1 }).then(function () { return _vm.$emit('updated'); })}}},[_c('span',[_vm._v("Activate")])]),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"warning","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.updateAllSlots({ active: 0 }).then(function () { return _vm.$emit('updated'); })}}},[_c('span',[_vm._v("Deactive")])]),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"error","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.deleteAllSlots({ active: 0 }).then(function () { return _vm.$emit('updated'); })}}},[_c('span',[_vm._v("Delete")])])]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }