<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <div class="d-flex align-center">
              <v-text-field
                v-model="form.value"
                class="mr-2"
                label="Price"
                :rules="[between(form.value, 0, form.type === 'percentage' ? 100 : maxPrice)]"
              ></v-text-field>
              <!-- :suffix="form.type === 'absolute' ? 'hkd' : '%'" -->

              <v-select
                v-model="form.type"
                placeholder="Price Type"
                :items="typeOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
                style="width: 100px;"
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <span>
          <span v-if="resource && resource.type === 'percentage'">= {{ resource.computed_price | money }}</span>
        </span>
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          SET
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { between } from '@core/utils/validation'
import { money } from '@core/utils/filter'

export default {
  filters: { money },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    maxPrice: {
      type: [Number, String],
      default: 99999999,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      value: props.resource ? props.resource.value : null,
      type: props.resource ? props.resource.type : 'absolute',
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const typeOptions = [
      { title: '$HK', value: 'absolute' },
      { title: '%', value: 'percentage' },
    ]

    return {
      form,
      formElem,
      validate,
      between,
      typeOptions,
    }
  },
}
</script>
