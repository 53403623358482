<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-file-input
              v-if="!disabledFields.includes('file')"
              v-model="form.file"
              :loading="loading"
              accept="image/*"
              label="Choose File"
            >
            </v-file-input>

            <v-select
              v-model="form.position"
              placeholder="Display Section"
              :items="positionOptions"
              item-text="title"
              item-value="value"
              :prepend-icon="icons.mdiViewQuilt"
              outlined
              dense
            ></v-select>

            <v-select
              v-model="form.device"
              placeholder="Device"
              :items="deviceOptions"
              item-text="title"
              item-value="value"
              :prepend-icon="icons.mdiCellphoneLink"
              outlined
              dense
            ></v-select>

            <v-select
              v-model="form.sku_id"
              placeholder="Sole For this SKU"
              :items="skuOptions"
              item-text="title"
              item-value="value"
              outlined
              dense
            ></v-select>

            <v-text-field
              v-model="form.sort"
              label="Sort Order"
              :rules="[between(form.sort,0,999)]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
        <v-btn
          v-if="resource"
          :loading="loading"
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        API error: {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { mdiCellphoneLink, mdiViewQuilt } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { required, between } from '@core/utils/validation'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    skuOptions: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      file: null,
      device: props.resource ? props.resource.device : 'mobile',
      position: props.resource ? props.resource.position : 'carousel',
      sort: props.resource ? props.resource.sort : 10,
      sku_id: props.resource ? props.resource.sku_id : null,
    }

    console.log('init form', initialForm)

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const deviceOptions = [
      { title: 'Mobile', value: 'mobile' },
      { title: 'Desktop', value: 'desktop' },
    ]

    const positionOptions = [
      { title: 'Carousel', value: 'carousel' },
      { title: 'Thumbnail', value: 'thumbnail' },
    ]

    return {
      form,
      icons: {
        mdiCellphoneLink,
        mdiViewQuilt,
      },

      formElem,
      validate,
      required,
      between,

      deviceOptions,
      positionOptions,
    }
  },
}
</script>
