<template>
  <v-card class="mb-7">
    <v-card-title>
      <span>SKU List</span>
      <v-spacer></v-spacer>
      <!-- <v-btn
        color="primary"
        class="mb-4 me-3"
        @click="$router.push({
          name: 'sku-create-resource',
          query: { product_id: product.id }
        })"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        <span>Add New SKU</span>
      </v-btn> -->
    </v-card-title>

    <v-data-table
      :headers="tableColumnHeaders"
      :items="product.skus"
      :items-per-page="99"
      hide-default-footer
    >
      <!-- name -->
      <template #[`item.name`]="{item}">
        <div class="d-flex align-items-center">
          <img
            v-if="skuMedium(item)"
            :src="skuMedium(item).instance.thumbnail"
            class="img img-thumbnail mr-2"
          >
          <div class="d-flex align-center">
            {{ item.name.en }}
          </div>
        </div>
      </template>

      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          :color="item.status === 'published' && item.active ? 'success' : 'secondary'"
        >
          {{ item.status === 'published' ? '' : item.status }}
          {{ item.active ? 'active' : 'disabled' }}
        </v-chip>
      </template>

      <!-- stock -->
      <template #[`item.stock`]="{item}">
        {{ item.stock }}
      </template>

      <!-- stock -->
      <template #[`item.language`]="{item}">
        <v-chip
          v-for="lang in item.languages"
          :key="lang.id"
          class="ml-s"
          x-small
        >
          {{ lang.name.en }}
        </v-chip>
      </template>

      <!-- Action -->
      <template #[`item.action`]="{item}">
        <v-menu
          bottom
          left
        >
          <!--
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

         <v-list>
            <v-list-item
              :to="{name:'sku-update-resource',query:{sku_id:item.id, product_id:product.id}}"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
                <span>Edit</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="item.status !== 'published'"
              color="success"
              @click="publishSku(item.id).then(() => $emit('updated'))"
            >
              <v-list-item-title>
                <span>Publish</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="item.status === 'published'"
              color="error"
              @click="unpublishSku(item.id).then(() => $emit('updated'))"
            >
              <v-list-item-title>
                <span>Unpublish</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="item.status === 'draft'"
              link
              @click="confirmTrashSku(item).then(() => $emit('updated'))"
            >
              <v-list-item-title>
                <v-icon
                  size="20"
                  class="me-2"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
                <span>Trash</span>
              </v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiPlus, mdiPencil, mdiDeleteOutline } from '@mdi/js'
import useProduct from '@/views/product/useProduct'

export default {
  props: {
    product: { type: Object, required: true },
  },

  setup(props) {
    const tableColumnHeaders = [
      { text: 'NAME', value: 'name', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },
      { text: 'STOCK', value: 'stock', sortable: true },
      { text: 'LANGUAGES', value: 'language', sortable: true },
      { text: 'ACTION', value: 'action', sortable: false },
    ]

    const { publishSku, unpublishSku, confirmTrashSku } = useProduct()

    const skuMedium = sku => props.product.media.find(m => m.sku_id === sku.id)

    return {
      tableColumnHeaders,
      publishSku,
      unpublishSku,
      confirmTrashSku,
      skuMedium,
      icons: {
        mdiDotsVertical,
        mdiPlus,
        mdiPencil,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style scoped>
.img-thumbnail {
  max-width: 50px;
  display: block;
}
</style>
