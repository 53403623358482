<template>
  <v-card>
    <!-- <v-card-title>
      <h1>Product Media Gallery</h1>
    </v-card-title> -->

    <v-card-text>
      <div class="d-flex justify-space-between">
        <div
          v-if="product"
          class="mb-5"
        >
          product: {{ product.title.en }}
        </div>

        <div
          class="mb-5"
        >
          Mode:
          <template
            v-if="resource"
          >
            <v-chip>
              updating #{{ resource.id }}
            </v-chip>
            <a
              class="ml-3"
              @click="resource = null"
            >Switch to Upload</a>
            <span class="ml-3">|</span>
            <a
              class="ml-3"
              @click="confirmDelete(resource)"
            >Delete this media</a>
          </template>
          <v-chip v-else>
            Upload
          </v-chip>
        </div>
      </div>

      <!--
      <div class="height-placeholder">
          <media-form
          v-if="formReady"
          :mode="resource ? 'update' : 'create'"
          :resource="resource"
          :loading="loading"
          :disabled-fields="[ resource ? 'file' : '']"
          :errors="errors"
          :sku-options="skuOptions"
          @submit="handleSubmit"
          @cancel="resource = false"
        />
      </div>
         -->

      <h2 class="mt-10">
        Current Media
      </h2>
      <v-row class="mt-5">
        <v-col
          v-for="medium in product.media"
          :key="medium.id"
          cols="2"
        >
          <!-- <v-badge>
            <template #badge>
              <v-icon
                class="cursor-pointer"
                @click="confirmDelete(medium)"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <img
              :src="medium.instance.thumbnail"
              alt=""
              class="img img-thumbnail"
            >
          </v-badge> -->
          <img
            :src="medium.instance.thumbnail"
            alt=""
            class="img img-thumbnail"
          >

          <div>{{ medium.sku_id ? `SKU# ${medium.sku_id}` : medium.position }} - {{ medium.device }} - {{ medium.sort }}</div>

          <!-- <v-btn @click="edit(medium)">
            Edit
          </v-btn> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { confirm, extractErrors, notifyErrors, notifySuccess } from '@/assets/js/utils'
import { ref } from '@vue/composition-api'
import { storePhoto, updatePhoto, destroyPhoto } from '@api/product'
import MediaForm from './ProductMediaForm.vue'

export default {
  components: { MediaForm },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const productId = props.product.id

    const loading = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const errors = ref([])

    const handleStore = form => {
      const formData = new FormData()

      Object.entries(form).forEach(([key, value]) => {
        if (value) formData.append(key, value)
      })

      formData.append('product_id', productId)

      return storePhoto(formData)
    }

    const handleUpdate = form => updatePhoto(resource.value.id, { ...form })

    const handleSubmit = async form => {
      loading.value = true
      errors.value = []

      const action = resource.value ? handleUpdate : handleStore

      await action(form)
        .then(() => {
          emit('updated')
          notifySuccess({ content: 'Media Updated.' })
        })
        .catch(err => {
          errors.value = extractErrors(err)
        })

      loading.value = false
    }

    const refreshForm = () => {
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const skuOptions = props.product.skus.map(s => ({
      title: s.name.en,
      value: s.id,
    }))

    skuOptions.push({
      title: 'Not attached to SKU.',
      value: null,
    })

    const edit = medium => {
      resource.value = medium
      refreshForm()
    }

    const confirmDelete = async medium => {
      await confirm({
        title: 'Confirm Action',
        content: 'Confirm delete this media?',
      })

      destroyPhoto(medium.id)
        .then(() => {
          emit('updated')
        })
        .catch(notifyErrors)
    }

    return {
      errors,
      loading,
      handleSubmit,

      resource,
      edit,
      skuOptions,
      formReady,

      confirmDelete,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>
.img {
  max-width: 100%;
}
.height-placeholder {
  min-height: 360px;
}
</style>
