<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div class="mt-2">
      <h4>
        Redeemable Date: {{ form.applicable_at || 'Any time.' }}
        <v-icon
          v-if="form.applicable_at"
          @click="form.applicable_at = null"
        >
          {{ icons.mdiCloseCircle }}
        </v-icon>
      </h4>
      <v-date-picker
        v-if="form.applicable_at"
        v-model="form.applicable_at"
      />

      <a
        v-else
        @click="setApplicableAtDate"
      >Set Redeemable Date</a>
    </div>

    <div class="mt-2">
      <h4>
        Stop purchase Date: {{ form.last_purchasable_at || 'Any time.' }}
        <v-icon
          v-if="form.last_purchasable_at"
          @click="form.last_purchasable_at = null"
        >
          {{ icons.mdiCloseCircle }}
        </v-icon>
      </h4>
      <v-date-picker
        v-if="form.last_purchasable_at"
        v-model="form.last_purchasable_at"
      />

      <a
        v-else
        @click="setLastPurchaseAtDate"
      >Set Stop Purchase Date</a>
    </div>

    <div class="mt-2">
      <h4>
        Expiry Date (Reference Only): {{ form.expires_at || 'Never expired.' }}
        <v-icon
          v-if="form.expires_at"
          @click="form.expires_at = null"
        >
          {{ icons.mdiCloseCircle }}
        </v-icon>
      </h4>
      <v-date-picker
        v-if="form.expires_at"
        v-model="form.expires_at"
      />
      <a
        v-else
        @click="setExpiredAtDate"
      >Set Expired Date</a>
    </div>

    <div>
      <v-textarea
        v-model="codesString"
        label="voucher codes"
        hint="enter codes seperated by comma, space or enter line break (abc123, gogo321)"
      >
      </v-textarea>
      total {{ codesArray.length }} codes. Max 999 codes.
    </div>

    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Generate
      </v-btn>
    </div>

    <v-alert
      v-for="err in errors"
      :key="err"
      type="warning"
    >
      {{ err }}
    </v-alert>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCloseCircle } from '@mdi/js'
import { storeVouchersFromGroup } from '@api/product'
import { computed, ref } from '@vue/composition-api'
import { extractErrors } from '@/assets/js/utils'
import moment from 'moment'
import DateTimePicker from '@/components/form/DateTimePicker.vue'

export default {
  components: { DateTimePicker },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    sku: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const codesString = ref('')
    const form = ref({
      codes: [],
      applicable_at: props.resource && props.resource.applicable_at ? moment(props.resource.applicable_at).format('YYYY-MM-DD') : null,
      last_purchasable_at: props.resource && props.resource.last_purchasable_at ? moment(props.resource.last_purchasable_at).format('YYYY-MM-DD') : null,
      expires_at: props.resource && props.resource.expires_at ? moment(props.resource.expires_at).format('YYYY-MM-DD') : null,
    })

    const codesArray = computed(() =>
      codesString.value
        .replace(/[ ]/g, ',')
        .split(/[\n,]/)
        .filter(val => val),
    )

    const setApplicableAtDate = () => {
      form.value.applicable_at = moment().add(3, 'days').format('YYYY-MM-DD')
    }

    const setLastPurchaseAtDate = () => {
      form.value.last_purchasable_at = moment().add(3, 'days').format('YYYY-MM-DD')
    }

    const setExpiredAtDate = () => {
      form.value.expires_at = moment().add(3, 'months').format('YYYY-MM-DD')
    }

    const handleSubmit = async () => {
      const data = {
        voucher_group_id: props.resource.id,
        codes: codesArray.value,
        applicable_at: form.value.applicable_at ? moment(form.value.applicable_at).toString() : null,
        last_purchasable_at: form.value.last_purchasable_at ? moment(form.value.last_purchasable_at).toString() : null,
        expires_at: form.value.expires_at ? moment(form.value.expires_at).toString() : null,
      }

      loading.value = true
      const request = storeVouchersFromGroup(data)
      await request
        .then(res => {
          emit('created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = extractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      form,
      codesString,
      codesArray,

      setApplicableAtDate,
      setLastPurchaseAtDate,
      setExpiredAtDate,

      icons: {
        mdiClose,
        mdiCloseCircle,
      },
    }
  },
}
</script>
