<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.keywords"
          label="Keywords"
          class="mt-5"
        />

        <translatable-input
          v-model="form.description"
          label="Description"
          class="mt-5"
        />

        <p>Sometimes this description is also used in short description (summary) of the item (e.g. product)</p>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
import TranslatableInput from '@/components/form/TranslatableInput.vue'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import { required } from '@core/utils/validation'
import { translatable } from '@/assets/js/utils'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      keywords: translatable(props.resource, 'keywords'),
      description: translatable(props.resource, 'description'),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
