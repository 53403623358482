<template>
  <v-row>
    <v-col>
      <!-- <voucher-aside
        v-if="aside === 'voucher'"
        :active="true"
        :sku="sku"
        :resource="voucher"
        @updated="emitUpdated"
        @aside="bol => { closeAside(bol); !bol && refresh() } "
      /> -->
    </v-col>
    <v-col :cols="12">
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="vouchers"
        :options.sync="options"
        :server-items-length="vouchers.length"
        :loading="loading"
        :height="400"
        show-select
        hide-default-footer
        fixed-header
      >
        <template #[`item.from`]="{item}">
          <span v-if="item.applicable_at">{{ item.applicable_at | dateSimple }}</span>
          <span v-else>Any time.</span>
        </template>

        <template #[`item.to`]="{item}">
          <span v-if="item.last_purchasable_at">{{ item.last_purchasable_at | dateSimple }}</span>
          <span v-else>Never</span>
        </template>

        <template #[`item.expire`]="{item}">
          <span v-if="item.expires_at">{{ item.expires_at | dateSimple }}</span>
          <span v-else>Never expires</span>
        </template>

        <template #[`item.redeemed_at`]="{item}">
          <span v-if="item.redeemed_at">{{ item.redeemed_at | dateSimple }}</span>
          <span v-else>Not used</span>
        </template>

        <template #[`item.code`]="{item}">
          {{ item.code }}
        </template>

        <template #[`item.owner`]="{item}">
          <span v-if="item.owner">{{ item.owner.email }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <span v-if="item.status">{{ item.status }}</span>
        </template>

        <!-- actions -->
        <!-- <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template> -->
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { mdiPencil, mdiDotsVertical, mdiDeleteOutline } from '@mdi/js'
import { dateSimple } from '@core/utils/filter'
import { t } from '@/plugins/i18n'
import useVoucherForm from './useVoucherForm'
import VoucherAside from './VoucherAside.vue'

export default {
  components: { VoucherAside },
  filters: { dateSimple },
  props: {
    sku: {
      type: Object,
      required: true,
    },
    rule: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const date = ref(moment().format('YYYY-MM-DD'))

    const { loadVouchers, vouchers } = useVoucherForm()

    const refresh = () =>
      loadVouchers(props.sku, {
        started_at_from: moment(date.value).startOf('month').format(),
        end_at_to: moment(date.value).endOf('month').format(),
        take: 999,
      })

    onMounted(() => refresh())

    watch([date], refresh)

    const tableColumns = [
      { text: 'USABLE SINCE', value: 'from' },
      { text: 'STOP PURCHASE', value: 'to' },
      { text: 'EXPIRES', value: 'expire' },
      { text: 'REDEEMED AT', value: 'redeemed_at' },
      { text: 'CODE', value: 'code' },
      { text: 'OWNER', value: 'owner' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })

    const voucher = ref(null)
    const aside = ref('') // voucher_group, voucher, generator

    const closeAside = bol => {
      if (!bol) {
        voucher.value = null
        aside.value = null
      }
    }

    const emitUpdated = () => {
      closeAside(false)
      emit('updated')
    }

    const toggleAside = v => {
      voucher.value = v
      aside.value = 'voucher'
    }

    return {
      date,
      vouchers,

      tableColumns,
      selectedRows: ref([]),
      loading: ref(false),
      options,

      toggleAside,
      closeAside,
      emitUpdated,
      aside,
      voucher,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
