<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <translatable-input
              v-model="form.name"
              label="Purchase Unit Name"
              class="mt-5"
            />

            <v-text-field
              v-model="form.selling_price"
              label="Base Selling Price (what customer pays)"
              suffix="hkd"
              :rules="[between(form.selling_price, 1, parseInt(form.list_price) || 99999999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.list_price"
              label="Original cross out price, input 0 if don't want to cross out."
              :rules="listPriceRules"
              suffix="hkd"
            ></v-text-field>

            <v-text-field
              v-model="form.msr_price"
              label="Suggested Retail price"
              suffix="hkd"
              :rules="[form.msr_price ? between(form.msr_price, form.selling_price, 99999999) : null]"
            ></v-text-field>

            <div class="d-flex align-center">
              <v-text-field
                v-model="form.cost_value"
                class="mr-2"
                label="Cost"
                :suffix="form.cost_type === 'absolute' ? 'hkd' : '%'"
                :rules="[between(form.cost_value, 0, form.cost_type === 'percentage' ? 100 : form.selling_price)]"
              ></v-text-field>

              <v-select
                v-model="form.cost_type"
                placeholder="Cost Type"
                :items="costTypeOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
              ></v-select>
            </div>

            <v-divider class="mb-5" />

            <v-switch
              v-model="form.main"
              color="primary"
              label="Main Price"
              :messages="[ 'Regard this as main SKU price.' ]"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import TranslatableInput from '@/components/form/TranslatableInput.vue'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import { between } from '@core/utils/validation'
import { translatable } from '@/assets/js/utils'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: translatable(props.resource, 'name'),
      msr_price: props.resource ? props.resource.msr_price : null,
      cost_value: props.resource ? props.resource.cost_value : null,
      cost_type: props.resource ? props.resource.cost_type : 'absolute',
      selling_price: props.resource ? props.resource.selling_price : 1,
      list_price: props.resource ? props.resource.list_price : 0,
      main: props.resource ? props.resource.main : 1,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const costTypeOptions = [
      { title: 'absolute', value: 'absolute' },
      { title: 'percentage', value: 'percentage' },
    ]

    const listPriceRules = computed(() => {
      const rules = []
      console.log('what is ', form.value.list_price)
      if (form.value.list_price != '0') {
        rules.push(between(form.value.list_price, form.value.selling_price, 99999999))
      }

      return rules
    })

    return {
      form,
      formElem,
      validate,
      between,
      listPriceRules,
      costTypeOptions,
    }
  },
}
</script>
