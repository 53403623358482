<template>
  <v-select
    label="Visible Channel"
    :items="channelOptions"
    item-text="title"
    item-value="value"
    :value="channel ? channel.id : null"
    outlined
    dense
    hide-details
    clearable
    style="width: 100px;"
    @change="setChannel"
  ></v-select>
</template>

<script>
import store from '@/store'
import channelStore from '@/store/channel'
import { computed } from '@vue/composition-api'
import { t } from '@/plugins/i18n'

export default {
  setup() {
    const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id })))

    const setChannel = id => {
      store.dispatch(
        'channel/setChannel',
        channelStore.state.channels.find(ch => ch.id === id),
      )
    }

    return {
      channelOptions,
      setChannel,
      channel: channelStore.state.channel,
    }
  },
}
</script>
