<template>
  <v-form>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="form.sync_ids"
              placeholder="Select 0 or more locations"
              :items="locationsOptions"
              item-text="title"
              item-value="value"
              label="Available Branches"
              outlined
              chips
              multiple
              hide-details
              disabled
              @change="handleSync"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import merchantStore from '@/store/merchant'
import store from '@/store'
import axios from '@axios'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /merchant/products/1/sync-locations
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    // const typeOptions = [
    //   { title: 'Service Category', value: 'service' },
    //   { title: 'Tangible Category', value: 'tangible' },
    // ]

    const locationsOptions = computed(() => merchantStore.state.branches.map(branch => ({ title: branch.name.en, value: branch.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')
      })
    }

    return {
      form,

      // typeOptions,
      locationsOptions,
      handleSync,
    }
  },
}
</script>
