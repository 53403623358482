var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col'),_c('v-col',{attrs:{"cols":12}},[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.vouchers,"options":_vm.options,"server-items-length":_vm.vouchers.length,"loading":_vm.loading,"height":400,"show-select":"","hide-default-footer":"","fixed-header":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [(item.applicable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.applicable_at)))]):_c('span',[_vm._v("Any time.")])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [(item.last_purchasable_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.last_purchasable_at)))]):_c('span',[_vm._v("Never")])]}},{key:"item.expire",fn:function(ref){
var item = ref.item;
return [(item.expires_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.expires_at)))]):_c('span',[_vm._v("Never expires")])]}},{key:"item.redeemed_at",fn:function(ref){
var item = ref.item;
return [(item.redeemed_at)?_c('span',[_vm._v(_vm._s(_vm._f("dateSimple")(item.redeemed_at)))]):_c('span',[_vm._v("Not used")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [(item.owner)?_c('span',[_vm._v(_vm._s(item.owner.email))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_vm._v(_vm._s(item.status))]):_vm._e()]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }