var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_c('span',[_vm._v("SKU List")]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.product.skus,"items-per-page":99,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[(_vm.skuMedium(item))?_c('img',{staticClass:"img img-thumbnail mr-2",attrs:{"src":_vm.skuMedium(item).instance.thumbnail}}):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.name.en)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 'published' && item.active ? 'success' : 'secondary'}},[_vm._v(" "+_vm._s(item.status === 'published' ? '' : item.status)+" "+_vm._s(item.active ? 'active' : 'disabled')+" ")])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stock)+" ")]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return _vm._l((item.languages),function(lang){return _c('v-chip',{key:lang.id,staticClass:"ml-s",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(lang.name.en)+" ")])})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }