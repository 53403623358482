var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.sku.name.en))]),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.priceGroups,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" - "+_vm._s(item.name.en)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[(price.main && _vm.product.nature === 'service')?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiStar)+" ")]):_vm._e(),_vm._v(" "+_vm._s(price.name.en)+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)})}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[(price.computed_cost)?_c('span',[_vm._v(_vm._s(_vm._f("priceFormat")(price.computed_cost)))]):_vm._e(),(price.msr_price)?_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(_vm._f("priceFormat")(price.msr_price))+")")]):_c('span',{staticClass:"ml-1"},[_vm._v("(na)")]),(price.cost_type === 'percentage')?_c('span',{staticClass:"ml-1"},[_vm._v("("+_vm._s(price.cost_value)+"%)")]):_vm._e(),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return _vm._l((item.prices),function(price){return _c('div',{key:price.id,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.togglePriceAside(price)}}},[_vm._v(" "+_vm._s(_vm._f("priceFormat")(price.selling_price))+" "),(parseInt(price.list_price))?_c('span',{staticClass:"ml-2 cross-out"},[_vm._v(_vm._s(price.list_price))]):_vm._e(),_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)})}},{key:"item.action",fn:function(ref){
var item = ref.item;
return undefined}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }