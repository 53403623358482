<template>
  <div>
    <!-- <price-aside
      v-if="aside === 'price'"
      :active="true"
      :price-group="priceGroupData"
      :resource="priceData"
      @changed="refresh"
      @aside="closeAside"
    /> -->

    <!-- <channel-price-aside-update
      v-if="priceData && aside === 'channel'"
      :active="true"
      :sku="sku"
      :price="priceData"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    /> -->

    <!-- <price-group-aside
      v-if="aside === 'price_group'"
      :active="true"
      :sku="sku"
      :resource="priceGroupData"
      @updated="refresh"
      @created="createdPriceGroup"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    /> -->

    <v-card class="mb-7">
      <v-card-title>
        <span>{{ sku.name.en }}</span>
        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="(product.nature === 'service') || priceGroups.length === 0"
          color="primary"
          class="mb-4 me-3"
          @click="toggleGroupAside(null, 'price_group')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Group</span>
        </v-btn> -->
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="priceGroups"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }} - {{ item.name.en }}
          </div>
        </template>

        <!-- name -->
        <template #[`item.name`]="{item}">
          <div
            v-for="price in item.prices"
            :key="price.id"
            class="cursor-pointer"
            @click="togglePriceAside(price)"
          >
            <v-icon v-if="price.main && product.nature === 'service'">
              {{ icons.mdiStar }}
            </v-icon>
            {{ price.name.en }}
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <!-- cost / msrp -->
        <template #[`item.cost`]="{item}">
          <div
            v-for="price in item.prices"
            :key="price.id"
            class="cursor-pointer"
            @click="togglePriceAside(price)"
          >
            <span v-if="price.computed_cost">{{ price.computed_cost| priceFormat }}</span>
            <span
              v-if="price.msr_price"
              class="ml-1"
            >({{ price.msr_price | priceFormat }})</span>
            <span
              v-else
              class="ml-1"
            >(na)</span>
            <span
              v-if="price.cost_type === 'percentage'"
              class="ml-1"
            >({{ price.cost_value }}%)</span>
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <!-- price -->
        <template #[`item.price`]="{item}">
          <div
            v-for="price in item.prices"
            :key="price.id"
            class="cursor-pointer"
            @click="togglePriceAside(price)"
          >
            {{ price.selling_price | priceFormat }}
            <span
              v-if="parseInt(price.list_price)"
              class="ml-2 cross-out"
            >{{ price.list_price }}</span>
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <!-- Channel Prices -->
        <!-- <template #[`item.ch_price`]="{item}">
          <div
            v-for="price in item.prices"
            :key="price.id"
            class="cursor-pointer"
            @click="toggleChannelAside(price)"
          >
            {{ channelPriceRange(price) }}
            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
          </div>
        </template> -->

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <!-- <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link @click="toggleGroupAside(item, 'price_group')">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update Group</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="togglePriceAside(null, item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Add New Price</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete {{ item.name.en }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus, mdiStar } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

import { price as priceFormat } from '@core/utils/filter'
import ChannelPriceAsideUpdate from '../../price-resource/ChannelPriceAsideUpdate.vue'
import PriceAside from '../../price-resource/PriceAside.vue'
import PriceGroupAside from '../../price-resource/PriceGroupAside.vue'
import usePriceForm from '../../price-resource/usePriceForm'

export default {
  components: {
    PriceAside,
    PriceGroupAside,
    ChannelPriceAsideUpdate,
  },

  filters: { priceFormat },

  props: {
    product: { type: Object, required: true },
    sku: { type: Object, required: true },
  },

  setup(props) {
    const { priceGroups, loadPriceGroups } = usePriceForm()

    const tableColumnHeaders = computed(() => {
      let options = [
        { text: 'PRICE NAME', value: 'name', sortable: false },
        { text: 'COST MSRP', value: 'cost', sortable: false },
        { text: 'BASE SELLING PRICE', value: 'price', sortable: false },

        // { text: 'CHANNEL SELLING PRICES', value: 'ch_price', sortable: false },
      ]
      if (props.product.nature === 'service') {
        options = [{ text: 'ID - GROUP', value: 'id', sortable: false }].concat(options)
      }
      if (props.product.nature === 'service' || !priceGroups.length) {
        options.push({ text: 'GROUP ACTION', value: 'action', sortable: false })
      }

      return options
    })

    const priceData = ref(null)
    const priceGroupData = ref(null)
    const aside = ref('') // channel, price

    const closeAside = bol => {
      if (!bol) {
        priceData.value = null
        priceGroupData.value = null
        aside.value = null
      }
    }

    const toggleChannelAside = price => {
      priceData.value = price
      aside.value = 'channel'
    }

    const toggleGroupAside = group => {
      priceGroupData.value = group
      aside.value = 'price_group'
    }

    const togglePriceAside = (price, group = null) => {
      console.log('toggle price', price, group)
      priceData.value = price
      priceGroupData.value = group
      aside.value = 'price'
    }

    const refresh = () => {
      loadPriceGroups(props.sku)
      closeAside()
    }

    watch(props.sku, sku => {
      console.log('sku changed', sku)
      refresh()
    })

    onMounted(() => {
      refresh()
    })

    // const channelPriceRange = price => {
    //   const prices = []
    //   const { channel } = channelStore.state

    //   const matchPrices = price.channel_prices.filter(cp => (channel ? cp.channel_id === channel.id : true))

    //   matchPrices.forEach(chPrice => {
    //     // prices.push(chPrice.type === 'absolute' ? chPrice.value : (chPrice.value * price.selling_price) / 100)
    //     prices.push(chPrice.computed_price)
    //   })

    //   const min = Math.min(...prices)
    //   const max = Math.max(...prices)
    //   console.log('what is prices', prices, price, min, max, channel, matchPrices)

    //   if (!prices.length) {
    //     return 'na'
    //   }
    //   if (prices.length === 1) {
    //     return `${priceFormat(max)}`
    //   }

    //   return `${priceFormat(min) || ''} - ${priceFormat(max) || ''}`
    // }

    const createdPriceGroup = group => {
      togglePriceAside(null, group)
    }

    return {
      priceGroups,

      // channelPriceRange,
      tableColumnHeaders,
      priceData,
      priceGroupData,
      aside,
      refresh,
      closeAside,
      createdPriceGroup,
      toggleChannelAside,
      togglePriceAside,
      toggleGroupAside,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiStar,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
